import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import Accounts from "./views/Accounts.vue";
import AccountsEdit from "./views/AccountsEdit.vue";
import * as UserService from "./services/user";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/accounts",
    name: "Accounts",
    component: Accounts
  },
  {
    path: "/account/",
    name: "AccountsEdit",
    component: AccountsEdit
  },
  {
    path: "/account/:idAccount?",
    name: "AccountsEdit",
    props: true,
    component: AccountsEdit
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, _from, next) => {
  if (to.name === "Login") {
    next();
    return;
  }

  if (await UserService.isLoggedIn()) {
    next();
    return;
  }

  next({ name: "Login" });
});

export default router;
