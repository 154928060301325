import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Axios, { AxiosError, AxiosResponse } from "axios";
import { DateTime } from "luxon";
import GAuth from "vue3-google-oauth2";

const gAuthOptions = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: "email",
  prompt: "select_account",
  fetch_basic_profile: false
};

Axios.defaults.baseURL = process.env.VUE_APP_API_BASE;
Axios.defaults.withCredentials = true;

function convertObjectToMoment(obj: unknown): unknown {
  if (!obj) {
    return obj;
  }

  if (typeof obj === "string" || obj instanceof String) {
    const converted = DateTime.fromFormat(
      obj as string,
      "yyyy-MM-dd'T'HH:mm:ss'.'SSS'Z'",
      { zone: "utc" }
    );
    if (converted.isValid) {
      return converted;
    } else {
      return obj;
    }
  } else if (Array.isArray(obj)) {
    return obj.map(el => {
      return convertObjectToMoment(el);
    });
  } else if (obj instanceof Date) {
    return DateTime.fromJSDate(obj);
  } else if (obj instanceof Object) {
    Object.keys(obj).forEach(key => {
      (obj as { [key: string]: unknown })[key] = convertObjectToMoment(
        (obj as { [key: string]: unknown })[key]
      );
    });

    return obj;
  } else {
    return obj;
  }
}

Axios.interceptors.response.use(
  (res: AxiosResponse<unknown>) => {
    res.data = convertObjectToMoment(res.data);
    return res;
  },
  err => {
    return Promise.reject(err);
  }
);

Axios.interceptors.response.use(undefined, (err: AxiosError) => {
  if (err.response && err.response.status === 401) {
    router.push({ name: "Login" });
    return Promise.reject(err);
  } else {
    return Promise.reject(err);
  }
});

createApp(App)
  .use(GAuth, gAuthOptions)
  .use(router)
  .mount("#app");
