
import { Options, Vue } from "vue-class-component";

@Options({
  components: {}
})
export default class Home extends Vue {
  async mounted() {
    console.log("Home");
  }
}
