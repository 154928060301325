
import { Options, Vue } from "vue-class-component";
import * as UserService from "../services/user";

@Options({})
export default class SiteHeader extends Vue {
  imageSrc = "";
  isAdmin = false;

  async mounted() {
    this.isAdmin = await UserService.isLoggedIn();
  }

  async updated() {
    this.isAdmin = await UserService.isLoggedIn();
  }

  async logout() {
    await UserService.logout();
    this.isAdmin = false;

    this.$router.push({ name: "Login" });
  }
}
