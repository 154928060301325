
import { Options, Vue } from "vue-class-component";
import InputCheckbox from "@/components/InputCheckbox.vue";
import * as UserService from "@/services/user";

@Options({ components: { InputCheckbox } })
export default class Login extends Vue {
  username = "";
  password = "";
  rememberme = false;

  async handleClickSignIn() {
    const googleUser = await (this as any).$gAuth.signIn();
    if (!googleUser) {
      return null;
    }

    const idToken: string = googleUser.getAuthResponse().id_token;
    const user = await UserService.login(idToken, this.rememberme);

    if (user) {
      this.$router.push("/");
    }
  }
}
