
import { Options, Vue } from "vue-class-component";
import InputText from "@/components/InputText.vue";
import * as AccountService from "@/services/account";
import { Account } from "@/services/account";
import * as showdown from "showdown";

var converter = new showdown.Converter();

interface InteractableAccount extends Account {
  open: boolean;
}

@Options({
  components: { InputText }
})
export default class Accounts extends Vue {
  websites: InteractableAccount[] = [];
  search = "";

  async mounted() {
    console.log(this.$refs.filter);
    (this.$refs.filter as any).$el.focus();

    const sites = await AccountService.getAccounts();
    this.websites = sites.map(site => {
      return { ...site, open: false };
    });
  }

  expand(site: InteractableAccount) {
    site.open = !site.open;
  }

  get filteredWebsites() {
    const search = this.search.toLowerCase().trim();

    if (!search) return this.websites;

    return this.websites.filter(function(site) {
      if (site.name && site.name.toLowerCase().search(search) > -1) return true;
      if (site.url && site.url.toLowerCase().search(search) > -1) return true;

      const login = site.logins.find(function(login) {
        if (login.username && login.username.toLowerCase().search(search) > -1)
          return true;
        if (login.password && login.password.toLowerCase().search(search) > -1)
          return true;
        if (login.note && login.note.toLowerCase().search(search) > -1)
          return true;
      });
      if (login) return true;
      return false;
    });
  }

  markdown(text: string) {
    return converter.makeHtml(text);
  }
}
