
import { Vue } from "vue-class-component";
import { Model, Prop, Watch } from "vue-property-decorator";

export default class InputText extends Vue {
  @Model("update:modelValue") readonly modelValue!: string;
  @Prop() readonly placeholder!: string;
  @Prop({ default: "text" }) readonly inputType!: string;

  onInput(event: Event): void {
    this.$emit("update:modelValue", (event.target as HTMLInputElement).value);
  }
}
