
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import SiteHeader from "./components/SiteHeader.vue";

@Options({
  components: {
    SiteHeader
  }
})
export default class App extends Vue {
  hideHeader = false;

  @Watch("$route", { immediate: true, deep: true })
  onRoute() {
    this.hideHeader = (this.$route.meta.hideHeader as boolean) || false;
  }
}
