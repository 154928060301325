import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d762802c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["type", "title", "value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modelValue)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.placeholder), 1))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      type: _ctx.inputType,
      title: _ctx.placeholder,
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
      placeholder: _ctx.placeholder
    }, null, 40, _hoisted_3)
  ]))
}