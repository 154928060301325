
import { Options, Vue } from "vue-class-component";
import InputText from "@/components/InputText.vue";
import * as AccountService from "@/services/account";
import { FillableAccount } from "@/services/account";
import { Prop } from "vue-property-decorator";

@Options({
  components: { InputText }
})
export default class AccountsEdit extends Vue {
  @Prop() readonly idAccount?: number;

  website: FillableAccount = { logins: [] };
  saving = false;

  async mounted() {
    if (this.idAccount)
      this.website = await AccountService.getAccountById(this.idAccount);
    else
      this.website = {
        logins: []
      };
  }

  addLogin() {
    this.website.logins.unshift({});
  }

  removeLogin(i: number) {
    this.website.logins.splice(i, 1);
  }

  async save() {
    try {
      this.saving = true;

      if (!this.website.idAccount) {
        await AccountService.createAccount(this.website);
      } else {
        await AccountService.updateAccount(this.website);
      }

      this.$router.push("/accounts");
    } catch (err) {
      alert(err);
    } finally {
      this.saving = false;
    }
  }
}
