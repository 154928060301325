import Axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";

export interface User {
  displayName: string;
}

interface LoginRequest {
  idToken: string;
  rememberme: boolean;
}

let currentUser: User | undefined;

export async function login(idToken: string, rememberme = false) {
  const user = await Axios.post<LoginRequest, AxiosResponse<User>>(
    "/user/login",
    {
      idToken,
      rememberme
    }
  ).then(res => {
    return res.data;
  });

  currentUser = user;
  return user;
}

export async function logout() {
  await Axios.post("/user/logout");
  currentUser = undefined;
}

export async function isLoggedIn() {
  const user = await getCurrentUser();
  return !!user;
}

export async function getCurrentUser() {
  if (currentUser) return currentUser;

  const hasSession = !!document.cookie
    .split("; ")
    .find(cookie => cookie.startsWith("connect.sid"))
    ?.split("=")[1];

  if (!hasSession) return undefined;
  currentUser = await Axios.get<User>("/user/current").then(res => res.data);
  return currentUser;
}
