
import { Vue } from "vue-class-component";
import { Model, Prop, Watch } from "vue-property-decorator";

export type AllowedTypes = "checkbox" | "radio";

export default class InputCheckbox extends Vue {
  @Model("update:modelValue") readonly modelValue!: boolean;
  @Prop() readonly placeholder!: string;
  @Prop() readonly value!: unknown;
  @Prop({ default: "" }) readonly name!: string;
  @Prop({ default: "checkbox" }) readonly type!: AllowedTypes;

  onInput(event: Event): void {
    this.$emit(
      "update:modelValue",
      (event.target as HTMLInputElement).checked ? this.value : undefined
    );
  }

  @Watch("modelValue")
  onModelChange() {
    (this.$refs.input as HTMLInputElement).checked =
      this.modelValue === this.value;
  }

  mounted() {
    (this.$refs.input as HTMLInputElement).checked =
      this.modelValue === this.value;
  }
}
