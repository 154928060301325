import Axios from "axios";

export interface AccountLogin {
  username: string;
  password: string;
  note: string;
  idLogin: number;
  owner: string;
}
export interface Account {
  idAccount: number;
  name: string;
  url: string;
  logins: AccountLogin[];
}
export interface FillableAccount extends Partial<Omit<Account, "logins">> {
  logins: Partial<AccountLogin>[];
}

export async function getAccounts() {
  return await Axios.get<Account[]>("/account").then(res => res.data);
}

export async function getAccountById(idAccount: number) {
  return await Axios.get<Account>(`/account/${idAccount}`).then(
    res => res.data
  );
}

export async function createAccount(account: FillableAccount) {
  if (account.idAccount) throw new Error("Unexpected ID");
  return await Axios.post<FillableAccount, void>(`/account`, account);
}

export async function updateAccount(account: FillableAccount) {
  if (!account.idAccount) throw new Error("ID required");
  return await Axios.put<FillableAccount, void>(
    `/account/${account.idAccount}`,
    account
  );
}
